.site__box {
  width: 80%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 5rem auto;

  &-img {
    width: 50rem;
    height: 50rem;
    max-width: 60rem;
    max-height: 60rem;
    min-width: 30rem;
    min-height: 30rem;

    img {
      height: 100%;
      width: 100%;
    }
  }

  &-info {
    width: 40%;
    display: flex;
    flex-direction: column;

    .site__box-title {
      @include fontXl;
    }
    .site__box-desc {
      @include fontXs;
      width: 80%;
    }
  }
}
