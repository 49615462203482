@media only screen and (max-width: 1300px) {
  .tourist__highlight {
    p {
      width: 70%;
      text-align: center;
    }
  }
  .nav {
    width: 80%;
  }

  // tours
  .tour__box-title {
    @include fontXs;
    text-decoration: underline;
    width: 90%;
  }

  // sites
  .site__box {
    width: 95%;
  }

  // restaurants
  .restaurant__bar {
    .restaurant-option,
    .bar-option {
      flex-direction: column;
    }
  }

  // Shopping page
  .shops {
    .display__box {
      // height: 45rem;
    }
  }

  // Culture Page
  .culture__box {
    &-img {
      width: 55%;
    }
    &-info {
      width: 40%;
      margin-left: 2rem;

      .culture__title {
        @include fontLg;
        width: 50%;
      }
    }
  }
}

@media only screen and (max-width: 1136px) {
  .nav {
    width: 95%;
  }

  .footer {
    width: 95%;
    margin: 8rem auto 0;
  }

  .discover {
    width: 100%;
  }

  .discover__box-img {
    width: 40rem;
    height: 40rem;
  }

  .park {
    flex-direction: column;
    text-align: center;

    &__info {
      width: 100%;
      .park__title {
        @include fontLg;
      }
      .park__desc {
        font-size: 1.2rem;
      }
      .park__map {
        width: 75%;
      }
    }

    &__photos {
      width: 100%;
      display: flex;
      flex-direction: column;

      * {
        height: 100%;
        border-radius: 13px;
      }
    }
  }

  // Tours Page
  .tour__box-icons {
    i.fas {
      font-size: 1.5rem;
    }
  }

  // Cities Page
  .cities {
    margin-top: 0;

    &__title {
      @include fontLg;
    }
  }

  .city {
    width: 100%;

    .city__info {
      display: flex;
      flex-direction: column;

      .city__desc {
        width: 95%;
      }

      .city__map {
        width: 100%;
        margin: 2rem auto;
      }
    }

    .city__images {
      display: block;
      * {
        margin: 2rem auto;
      }
    }
  }

  // Sites
  .sites {
    margin: 0 auto;
  }

  .site__box {
    &-img {
      width: 40rem;
      height: 40rem;
    }
    &-info {
      .site__box-title {
        @include fontLg;
      }
    }
  }

  // restaurants

  .restaurants {
    &__selection {
      .restaurant__bar {
        flex-direction: column;
        height: 20rem;
        width: 100%;

        .restaurant-option,
        .bar-option {
          height: 10rem;
          width: 100%;
          padding: 0;
        }
      }
      ul.restaurants__list {
        height: 70rem;

        & li {
          height: auto;
          img {
            display: none;
          }
        }
      }
    }
    &__info {
      .restaurant__desc {
        height: auto;
        padding: 0.5rem;

        p.restaurant__desc-title {
          @include fontXs;
        }
        p.restaurant__loc {
          font-weight: 600;
          @include fontXs;
        }
        p.restaurant__desc-description {
          @include fontXs;
        }
      }
    }
  }

  //Shops
  .shops {
    margin: 1rem auto;

    .display__box {
      &-info {
        .display__box-title {
          @include fontLg;
        }
      }
    }
  }

  // Accomodation
  .hotels {
    margin: 0 auto;

    .display__box {
      flex-direction: column;
      height: 100%;
      &-info {
        width: 100%;

        .display__box-title {
          @include fontLg;
        }
        .hotel__btn {
          margin-top: 1rem;
        }
      }

      &-img.display-hotels {
        height: 60rem;
        width: 100%;
      }
    }
  }

  // Fly Ethiopian
  .airline {
    width: 95%;

    &__info {
      width: 40%;
    }
  }
}

@media only screen and (max-width: 836px) {
  .header__jumbotron {
    &-title {
      @include fontLg;
      color: $color-light-1;
    }
    &-img {
      width: auto;
    }
  }

  .header__airline {
    img.header__jumbotron-img {
      position: absolute;
      right: 0;
    }
  }

  .nav-burger {
    display: block;
  }

  .nav-logo {
    display: block;
    width: 100%;
    margin: 1rem auto;

    img {
      width: 25%;
      margin: 0 auto;
    }
  }

  .nav-close {
    display: block;
  }

  .nav {
    position: fixed;
    z-index: 200;
    top: 0;
    left: -100vw;
    width: 100vw;
    height: 100%;
    overflow-y: hidden;
    background-color: $color-light-3;

    &__list {
      flex-direction: column;
      height: 70%;
      justify-content: space-around;

      &-item {
        margin: 0 auto;
        width: 100%;
        a {
          @include fontSm;
          width: 100%;
        }
      }

      &-item:nth-of-type(3) {
        order: -2;
        width: 80%;

        img {
          max-width: 50%;
        }
      }
    }
  }

  // footer
  .footer {
    &__menu {
      flex-wrap: wrap;
      margin-bottom: 1rem;
      &-item {
        width: 50%;
        margin: 2rem auto;
      }
    }
    &__terms {
      flex-direction: column;
      align-items: center;

      &-logo {
        width: 40%;
      }

      & * {
        margin: 2rem auto;
      }
    }
  }

  //Selection boxes
  .selection__box-title.selection__box-desc {
    padding: 0.5rem;
    width: 90%;
  }

  // change nav
  .discover__box {
    height: 100%;
    &-img {
      width: 35rem;
      height: 35rem;
    }
    &-info {
      .discover__box-title {
        @include fontLg;
      }
    }
  }

  .tours {
    width: 90%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }

  .tour__box {
    width: 45%;
    height: 30rem;
    margin-bottom: 2rem;
  }

  // sites
  .site__box {
    flex-direction: column;
    margin: 1rem auto;
    border-bottom: 1px solid $color-light-2;

    &-img {
      order: 2;
      margin: 2rem auto;
      width: 60rem;
      height: 60rem;
    }

    &-info {
      width: 100%;
      margin: 0 auto;
      text-align: center;
      .site__box-desc {
        width: 100%;
      }
    }
  }

  // Shops
  .shops {
    .display__box {
      flex-direction: column;
      height: 100%;
      text-align: center;
      min-height: unset;
      max-height: unset;

      &-info {
        width: 100%;

        .display__box-title {
          @include fontLg;
          align-self: center;
        }
      }

      &-img {
        height: 60rem;
        width: 95%;
        margin: 2rem auto;
      }
    }
  }

  // Culture Page
  .culture__box {
    flex-direction: column;
    margin: 4rem auto;
    width: 95%;
    height: auto;

    &-img {
      clip-path: none;
      position: relative;
      left: unset;
      width: 95%;
      margin: 2rem auto 0 auto;
    }

    &-info {
      width: 100%;
      margin: 0;
      text-align: center;

      .culture__title {
        @include fontLg;
        width: 100%;
      }
    }
  }

  .culture__points {
    p {
      @include fontXs;
    }
    i.fas {
      font-size: 1rem;
    }
  }

  //restaurants
  .header.restaurant__header {
    height: auto;
  }
  .restaurant__jumbotron {
    display: none;
  }
  .restaurants {
    display: flex;
    flex-direction: column;
    height: 100%;

    &__selection {
      width: 100%;
      height: 100%;

      .restaurant__bar {
        flex-direction: column;
        height: 20rem;
        width: 90%;
        margin: 0 auto;

        .restaurant-option,
        .bar-option {
          width: 100%;
          padding: 0;

          i.fas {
            font-size: 2rem;
          }
        }
      }
      ul.restaurants__list {
        height: 30rem;
        overflow-x: none;
        width: 80%;
        margin: 0 auto;
        padding: 0;

        & li {
          width: 95%;
          padding: 0;
          margin: 0 auto;
          img {
            display: block;
            width: 7rem;
            height: 7rem;
          }
        }
      }
    }
    &__info {
      width: 95%;
      margin: 0 auto;
      .restaurant__desc {
        height: auto;
        padding: 0.5rem;
      }
    }
  }

  // flyEthiopian
  .airline {
    flex-direction: column;

    &__info {
      width: 75%;
      margin: 2rem auto;
      text-align: center;

      .btn {
        margin: 2rem auto;
        align-self: center;
      }
    }

    &__images {
      width: 75%;
      margin: 0 auto 2rem;
      justify-content: center;
    }
  }
}

@media only screen and (max-width: 630px) {
  .header__jumbotron-title {
    @include fontMd;
  }

  .nav-logo {
    img {
      width: 45%;
    }
  }

  .tourist__highlight {
    height: 12rem;
    p {
      @include fontXs;
      width: 50%;
      text-align: center;
    }
  }

  .discover__box {
    height: 100%;
    flex-direction: column;
    text-align: center;
    width: 100%;
    margin: 3rem 0;

    &-img {
      order: 3;
      width: 100%;
      height: auto;
    }
    &-info {
      width: 100%;
      .discover__box-desc {
        margin: 2rem auto;
      }
    }
  }

  .banner {
    &__title {
      @include fontLg;
      color: #ffffff;
    }
    &__title,
    &__btn {
      margin-left: 5rem;
    }
  }

  //parks
  .park__map {
    width: 100%;
  }

  .tour__box {
    width: 100%;
  }

  .site__box {
    &-img {
      width: 95%;
      height: auto;
    }
  }

  // //restaurants
  // .header.restaurant__header {
  //   height: auto;
  // }
  // .restaurant__jumbotron {
  //   display: none;
  // }
  // .restaurants {
  //   display: flex;
  //   flex-direction: column;
  //   height: 100%;

  //   &__selection {
  //     width: 100%;
  //     height: 100%;

  //     .restaurant__bar {
  //       flex-direction: column;
  //       height: 20rem;
  //       width: 90%;
  //       margin: 0 auto;

  //       .restaurant-option,
  //       .bar-option {
  //         width: 100%;
  //         padding: 0;

  //         i.fas {
  //           font-size: 2rem;
  //         }
  //       }
  //     }
  //     ul.restaurants__list {
  //       height: 30rem;
  //       overflow-x: none;
  //       width: 80%;
  //       margin: 0 auto;
  //       padding: 0;

  //       & li {
  //         width: 95%;
  //         min-height: 10rem;
  //         height: 100%;
  //         padding: 0;
  //         margin: 0 auto;
  //         img {
  //           display: block;
  //           width: 7rem;
  //           height: 7rem;
  //         }
  //       }
  //     }
  //   }
  //   &__info {
  //     width: 95%;
  //     margin: 0 auto;
  //     .restaurant__desc {
  //       height: auto;
  //       padding: 0.5rem;
  //     }
  //   }
  // }

  // flyEthiopian
  .airline {
    &__info {
      width: 95%;
    }

    &__images {
      width: 95%;
      justify-content: center;
    }
  }

  .awards {
    &__title {
      @include fontSm;
    }
    &__img {
      img {
        width: 5rem;
        height: 5rem;
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  .nav-logo {
    img {
      width: 60%;
    }
  }

  .tourist__highlight {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    text-align: center;
    height: 15rem;

    p {
      width: 80%;
      margin: 0 auto;
      padding: 0;
    }
  }
  .banner {
    background-image: none;
    position: relative;
    height: 30rem;
    &__img {
      display: block;
      position: absolute;
      height: 100%;
      z-index: 1;
    }

    &__title {
      @include fontLg;
      color: $color-dark-2;
    }
  }

  // cities
  .city {
    .city__options {
      margin-bottom: 2rem;
      text-align: left;

      .city__name {
        width: 50%;
        margin: 1rem auto;
        @include fontXs;
      }
    }
    .city__info {
      // .city__map {
      //   width: 75%;
      // }
    }
  }

  // culture page
  .culture__points {
    display: none;
  }

  // hotels
  .hotels {
    .display__box {
      &-img {
        .display__img-2,
        .display__img-4 {
          width: 100%;
        }
        .display__img-3,
        .display__img-5 {
          display: none;
        }
      }
    }
  }

  // flyEthiopian
  .header__airline {
    .btn.jumbotron__btn {
      display: none;
    }
  }

  // Shops
  .shops {
    .display__box {
      &-img {
        height: 30rem;
      }
    }
  }

  .airline {
    &__info {
      width: 95%;
    }

    &__images {
      display: flex;
      flex-direction: column;
      height: auto;

      img {
        grid: unset;
        margin-bottom: 2rem;
      }
    }
  }

  .awards {
    &__title {
      @include fontSm;
    }
    &__img {
      img {
        width: 5rem;
        height: 5rem;
      }
    }
  }
}
