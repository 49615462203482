.culture__box {
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    height: auto;
    position: relative;
    overflow-x: hidden;

    &-img {
        min-height: 30rem;
        max-height: 80rem;
        width: 60%;
        margin: 1rem auto;
    }
    
    &-info {
        width: 20%;
        margin-left: 5rem;
        margin-top: 5rem;

        .culture__title {
            display: inline-block;
            @include fontLg;
            margin-bottom: 2rem;
        }
        .culture__desc {
            @include fontXs;
        }
    }
}