.restaurants {
  // margin: rem 0;
  &__selection {
    width: 30%;
    height: 100%;
    .restaurant__bar {
      width: 100%;
      height: 10rem;
      display: flex;
      justify-content: space-evenly;
      align-items: center;

      .restaurant-option,
      .bar-option {
        display: flex;
        align-items: center;
        justify-content: space-around;
        border: 2px solid #ffffff;
        width: 50%;
        height: 100%;
        padding: 0 2rem;
        background-color: $color-light-2;
        color: $color-light-1;
        cursor: pointer;

        &:hover {
          color: $color-light-3;
        }
        i.fas {
          font-size: 5.5rem;
        }
        p {
          @include fontSm;
        }
        &.option-active {
          color: $color-light-3;
        }
      }
    }

    ul.restaurants__list {
      height: 80rem;
      overflow-y: scroll;
      display: flex;
      flex-direction: column;
      padding: 1rem;
      border: 2px solid $color-dark-3;

      & li {
        display: flex;
        align-items: center;
        justify-content: space-around;
        border: 1px solid $color-dark-3;
        height: auto;

        &.restaurant-active {
          border: 2px solid $color-light-3;
          background-color: rgba($color-light-3, 0.2);
        }

        img {
          height: 13rem;
          width: 13rem;
          max-width: 30%;
        }

        .restaurant__info {
          width: 70%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          cursor: pointer;
          color: $color-dark-2;


          p.restaurant__info-name {
            @include fontMd;
            align-self: flex-start;
          }
          p i.fas {
            color: $color-dark-1;
          }

          p {
            @include fontXs;
            margin: .5rem 0;
          }
        }
      }
    }
  }
  &__info {
    width: 70%;
    .restaurant__desc {
      height: 20rem;
      padding: 1rem;
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      p.restaurant__desc-title {
        @include fontMd;
      }
      p.restaurant__loc {
        font-weight: 600;
        @include fontMd;
      }
      p.restaurant__desc-description {
        @include fontXs;
      }
      p {
        // @include fontSm;
        margin: 1rem 0;
      }
      p.restaurant__vegan {
        @include fontSm;
      }
      i.fas {
        font-size: 3rem;
        color: $color-dark-1;
      }
    }
  }
}

#map {
  height: 70rem;
  z-index:  0;
}
