.header {
  width: 100vw;
  height: 75vh;
  position: relative;
  display: inline-block;
  z-index: 1;
}

.header__short {
  height: 100%;
}

.discover {
  width: 80%;
  height: 100%;
  margin: 0 auto;
  padding-top: 3rem;
}

.hotspots {
  width: 95%;
  // height: 100%;
  margin: 4rem auto 0;
  padding-bottom: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__title {
    @include fontLg;
    margin-bottom: 5rem;
  }
}

.parks {
  margin: 5rem auto 5rem auto;
  width: 90%;

  &__selection {
    width: 100%;
  }
}

.tours {
  width: 80%;
  height: 100%;
  margin: 5rem auto;
  display: grid;
  grid-template-rows: 20% 22% 20% 22%;
  grid-template-columns: repeat(3, 33%);
  // grid-template-area: ;
  grid-row-gap: 3.5rem;
  grid-column-gap: 2.5rem;
}

.cities {
  width: 90%;
  height: 100%;
  margin: 5rem auto;

  &__title {
    @include fontXl;
    font-weight: 600;
  }
}

.sites {
  width: 100%;
  height: 100%;
  margin: 5rem auto;
  display: flex;
  flex-direction: column;
}

.shops,
.hotels {
  width: 90%;
  height: 100%;
  margin: 5rem auto;
}

.cultures {
  width: 100%;
  height: 100%;
}

.restaurants {
  width: 100%;
  height: 90rem;
  display: flex;
}

// third party selection swipers
.selection__container.swiper-container {
  z-index: 0;
}
.swiper-container.swiper-container-horizontal {
  z-index: 0;
}

.nav {
  width: 75%;
  margin: 0 auto;
  padding-top: 2rem;
  z-index: 10;

  &-burger {
    display: none;
    position: absolute;
    top: 5rem;
    left: 5%;
    font-size: 5rem;
    color: $color-light-3;
    cursor: pointer;

    &.dark {
      color: $color-dark-2;
    }
  }


  &-close {
    display: none;
    position: absolute;
    z-index: 50;
    top: 1rem;
    right: 5%;
    font-size: 5rem;
    color: $color-light-1;
    cursor: pointer;
  }

  &-logo {
    display: none;
    img {
      width: 20rem;
    }
  }

  &__list {
    display: flex;
    justify-content: center;
    align-items: center;

    &-item {
      width: 25%;
      position: relative;

      a {
        @include fontSm;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center;

        span.nav-item-icon {
          margin-left: 1rem;
          display: inline-block;
          font-size: 0.8rem;
        }
      }

      @include dropdowns(".nav", "top");
    }
  }
}

.header__jumbotron {
  width: 100%;
  height: 100%;
  max-height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -91;
  overflow: hidden;

  &-title {
    @include fontXl;
    color: $color-light-1;
    letter-spacing: 9px;
    font-weight: 700;
    position: absolute;
    left: 10%;
    top: 40%;
  }

  &-img {
    height: 100%;
  }
}

// Main Index
.main-index {
  width: 100%;
  height: auto;
  position: relative;
}

//footer
.footer {
  width: 80%;
  margin: 10rem auto 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__menu {
    width: 100%;
    margin-bottom: 4rem;
    display: flex;
    justify-content: space-around;

    &-item {
      width: 25%;
      position: relative;

      a {
        width: 100%;
        @include fontSm;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center;

        span.footer-item-icon {
          margin-left: 1rem;
          display: inline-block;
          font-size: 0.8rem;
        }
      }

      @include dropdowns(".footer", bottom);
    }
  }

  &__terms {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
    margin-bottom: 5rem;

    &-logo {
      width: 20%;

      a img {
        width: 80%;
        margin: 0 auto;
      }
    }

    .footer__copyright {
      text-align: center;
      @include fontSm;
      font-weight: 600;

      &-email {
        margin-bottom: 1rem;
      }
    }

    .footer__social {
      a i.fab {
        color: $color-dark-2;
        font-size: 5rem;
        margin-right: 2rem;
      }
    }
  }
}
