// // button
// // input
// // modal
// // Reusable Components

.btn {
  background-color: $color-light-3;
  padding: 1rem 2rem;
  color: $color-light-1;
  border-radius: 18px;
  @include fontSm;
  cursor: pointer;
  transition: all 0.1s ease-in-out;

  &:hover {
    background-color: #188ce0;
    box-shadow: $shadow;
  }
}

// Tourist Highlight
.tourist__highlight {
  width: 100%;
  height: 8rem;
  background-color: $color-dark-2;
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    color: $color-light-1;
    @include fontSm;
    padding-right: 3rem;
  }
}

.tourist__highlight.tourprovider {
  min-height: 8rem;
  height: auto;
  text-align: center;
  p {
    width: 75%;
    line-height: 1.5;
    margin: 1rem auto;
  }
}

//Index Discover
.discover__box {
  width: 100%;
  height: auto;
  min-height: 55rem;
  display: flex;
  align-items: center;
  justify-content: space-around;

  &-img {
    width: 50rem;
    height: 50rem;
    max-width: 50rem;
    overflow: hidden;

    img {
      height: 100%;
      width: 100%;
    }
  }

  &-info {
    width: 40%;

    .discover__box-title {
      @include fontXl;
      color: $color-dark-2;
      margin-bottom: 5rem;
    }

    .discover__box-desc {
      @include fontXs;
      line-height: 1.5;
      font-weight: 500;
      width: 80%;
    }
  }
}

.discover__box-img:nth-of-type(2) {
  justify-self: flex-start;
}

// Selection Slider
.selection {
  width: 100%;
  position: relative;

  &__container {
    width: 100%;
    height: 100%;
    transition: all 1s ease-out;
  }

  .selection__box {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    z-index: 1;
    cursor: pointer;
    // width: 25rem;
    height: 35rem;
    position: relative;
    border-radius: 13px;
    transition: all 0.3s ease-in;
    overflow: hidden;

    &-img {
      position: absolute;
      z-index: -1;
      height: 100%;
      width: 100%;
      border-radius: 13px;
    }

    &-title,
    &-desc {
      padding-left: 2rem;
      padding-bottom: 1rem;
      @include fontSm;
      font-weight: 600;
      color: $color-light-1;
    }
    &-title {
      transition: all 0.3s ease-in;
    }

    &:hover .selection__box-title {
      color: $color-light-3;
      font-size: 3rem;
    }

    &:hover {
      box-shadow: $shadow;
      transform: scale(1.05);
    }
  }
}

// Banner
.banner {
  z-index: -1;
  margin: 5rem 0;
  width: 100%;
  height: 40rem;
  background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    url("/src/img/ethiopianAirline.jpeg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  // opacity: $dark-color;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &__img {
    display: none;
  }

  &__title {
    width: fit-content;
    @include fontXl;
    text-align: left;
    color: $color-light-1;
    // color: rgb(32, 31, 31);
  }
  &__btn {
    align-self: flex-start;
  }

  &__title,
  &__btn {
    margin-left: 20rem;
    z-index: 4;
  }
}

// Display box
.display__box {
  height: 100%;
  min-height: 60rem;
  display: flex;
  justify-content: space-around;
  margin: 5rem 0;

  &-info {
    width: 30%;
    height: 100%;

    .display__box-title {
      @include fontXl;
    }
    .display__box-desc {
      @include fontXs;
    }
  }

  &-img {
    width: 60%;
    height: 60rem;

    img {
      margin: 0 auto;
      height: 100%;
      width: auto;
      max-width: 100%;
    }
  }
}

// .shops.display__box {
//   max-height: 60rem;
//   &-img {
//   }
// }

.culture__points {
  height: 10rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  p {
    @include fontMd;
  }
}
