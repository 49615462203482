.park {
  width: 90%;
  height: auto;
  margin: 0 auto;
  padding: 10rem 0;
  display: flex;
  justify-content: space-around;
  // align-items: center;

  &__info {
    width: 30%;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    text-align: center;

    .park__title {
      @include fontXl;
    }
    .park__desc {
      @include fontXs;
      line-height: 1.5;
    }
    .park__map {
      width: 100%;
      margin: 4rem auto;
    }
  }
  &__photos {
    width: 40%;
    height: 100%;
    display: flex;
    flex-direction: column;

    * {
      width: 100%;
      margin: 1rem auto;
      border-radius: 13px;
    }
  }
}
