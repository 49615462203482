.city {
  display: flex;
  flex-direction: column;

  &__options {
    // width: 33%;
    display: flex;
    // flex-direction: column;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-bottom: 1rem;

    .city__name {
      width: 25%;
      text-align: center;
      @include fontSm;
      //   width: fit-content;
      color: $color-light-2;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      margin-bottom: 1rem;

      &:hover {
        @include fontMd;
        color: grey;
      }

      &.city-active {
        @include fontMd;
        color: $color-dark-2;
        text-decoration: underline;
      }
    }
  }

  &__info {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 4rem;
    flex-wrap: wrap;

    .city__desc {
      @include fontSm;
      line-height: 1.5;
      width: 70%;
    }

    .city__map {
      width: 25%;
      margin: 0 auto;
    }
  }

  .city__images {
    width: 100%;
    margin-top: 4rem;
    display: grid;
    grid-template-columns: repeat(3, 30%);
    justify-content: space-around;
  }
}
