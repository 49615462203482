// // Resets
// // Typography
@import url("https://fonts.googleapis.com/css2?family=EB+Garamond:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600&display=swap");

html {
  font-size: 10px;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  width: 100vw;
  max-width: 100vw;
  overflow-x: hidden;
  height: 100%;
  position: relative;
}

img {
  display: block;
  width: 100%;
}

a {
  color: black;
  text-decoration: none;
  cursor: pointer;
  // @include fontMd;
}

button {
  border: none;
  outline: none;
  background: none;
}
