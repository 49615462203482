.header__airline {
    
    // height: 100%;
    // overflow: auto;
    // .header__jumbotron-title {
    //     color: $color-dark-2;
    //     text-align: center; 
    // }
    // .btn.jumbotron__btn {
    //     background-color: $color-dark-1;
    //     position: absolute;
    //     top: 80%;
    //     left: 20%;
    // }
}
 

.awards {
    height: 20rem;
    width:  100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;

    &__title {
        @include fontMd;
    }

    &__img {
        width: 100%;
        display: flex;
        justify-content: space-around;

        img {
            width: 10rem;
            height: 10rem;
        }
    }
}

.airline  {
    width: 80%;
    height: 100%;
    min-height: 30rem;
    margin: 5rem auto;
    display: flex;
    justify-content: space-around;

    &__info {
        width: 30%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: flex-start;

        &-desc {
            @include fontXs;
        }
        .btn {
            align-self: flex-start; 
            justify-self: flex-end;
            background-color: $color-dark-1;    
        }
    }
    &__images {
        width: 50%;
        height: auto;
        display: grid;
        grid-template-columns: 45% 45%;
        grid-template-rows: 50% 50%;
        grid-gap: 2rem;

        img {
            height: 100%;
            border-radius: 13px;

        }
        img:first-child {
            grid-column: 1/3;
        }
    }

}