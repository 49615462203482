// Variables
// Fonts
// Mixins
// user: <i class="fas fa-user"></i>
// cart: <i class="fas fa-shopping-bag"></i>
// search: <i class="fas fa-search"></i>
// menu: <i class="fas fa-bars"></i>
// ViewProduct: <i class="fas fa-plus-circle"></i>

$color-light-1: #ffffff;
$color-light-2: #c4c4c4;
$color-light-3: #3b98db;
$color-dark-1: #50a458;
$color-dark-2: #1c2c4b;
$color-dark-3: #000000;

$light-opacity: rgba($color-light-1, 0.4);
$dark-opacity: rgba($color-dark-3, 0.4);

$shadow: 0px 8px 16px 0px $dark-opacity;

// All Headers
@mixin fontXl {
  font-family: "EB Garamond", serif;
  color: $color-dark-2;
  font-size: 9.6rem;
  font-weight: 400;
  letter-spacing: 9.5%;
  margin-bottom: 5rem;
}

@mixin fontLg {
  font-family: "EB Garamond", serif;
  font-size: 6rem;
  font-weight: 500;
  letter-spacing: 5.5%;
  color: $color-dark-2;
}

// NavBtns or Article Heads
@mixin fontMd {
  font-size: 2.4rem;
  font-weight: 600;
  letter-spacing: 5.5%;
}

// Subheadings and
@mixin fontSm {
  font-size: 1.8rem;
  font-weight: 500;
  letter-spacing: 1.5px;
}

@mixin fontXs {
  font-size: 1.4rem;
  letter-spacing: 1.5px;
}

@mixin drawUnderline {
  &:after {
    content: "";
    height: 3px;
    width: 0;
    background: $color-light-2;
    position: absolute;
    bottom: 0;
    left: 0;
  }

  &:hover:after {
    animation: underline 0.5s ease-in;
    animation-fill-mode: forwards;
  }
}
