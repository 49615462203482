.tour__box {
  position: relative;
  box-shadow: $shadow;
  transition: all 0.3 s ease-in;

  &:hover {
    box-shadow: 0px 8px 16px 0px rgba($color-dark-3, 0.8);
    // transform: scale(1.01);
  }

  &-img {
    height: 100%;
  }

  &-title {
    position: absolute;
    bottom: 10%;
    left: 5%;
    @include fontMd;
    // font-weight: 600;
    color: $color-light-1;
    background-color: rgba($color-dark-2, 0.1);
  }

  &-icons {
    position: absolute;
    top: 5%;
    right: 5%;
    width: 20%;
    display: flex;
    justify-content: space-evenly;

    i.fas {
      color: $color-dark-1;
      font-size: 3rem;
    }
  }

  &:nth-of-type(1) {
    grid-column-start: 1;
    grid-column-end: 3;
    .tour__box-icons {
      width: 8%;
    }
  }
  &:nth-of-type(7) {
    grid-column-start: 2;
    grid-column-end: 4;
    .tour__box-icons {
      width: 8%;
    }
  }
}
