.display__box {
  
  &-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .hotel__btn {
      margin-top: 3rem;
      margin-bottom: 5rem;
    }
  }

  &-img.display-hotels {
    position: relative;
    height: 60rem;
    margin: auto 0;

    img {
      position: absolute;
      width: 50%;
      height: 30rem;
      border: 1px solid $color-light-1;
    }
    .display__img-1 {
      width: 15rem;
      height: auto;
      max-height: 15rem;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: $color-light-1;
      z-index: 1;
    }
    .display__img-2 {
      top: 0;
      left: 0;
    }
    .display__img-3 {
      top: 0;
      right: 0;
    }
    img.display__img-4 {
      bottom: 0;
      left: 0;
    }
    img.display__img-5 {
      bottom: 0;
      right: 0;
    }
  }
}
